<template>
  <div>
    <template v-for="(step, stepIndex) of config">
      <b-card
        class="mb-3"
        :key="stepIndex"
        :title="step.title"
        v-if="stepIndex === currentStep"
      >
        <b-row>
          <template v-for="(el, elementIndex) of step.elements">
            <b-col
              :key="elementIndex"
              :cols="el.sizes.xs"
              :sm="el.sizes.sm"
              :md="el.sizes.md"
              :lg="el.sizes.lg"
            >
              <b-form-group :label="el.label">
                <b-form-input
                  v-if="!needsOptions(el.type)"
                  :type="el.type"
                  v-model="value[el.field]"
                ></b-form-input>
                <b-form-checkbox-group
                  v-if="el.type === 'checkbox'"
                  v-model="value[el.field]"
                  :options="el.values"
                  stacked
                ></b-form-checkbox-group>
                <b-form-radio-group
                  v-if="el.type === 'radio'"
                  v-model="value[el.field]"
                  :options="el.values"
                  stacked
                ></b-form-radio-group>
                <b-form-select
                  v-if="el.type === 'dropdown'"
                  v-model="value[el.field]"
                  :options="el.values"
                ></b-form-select>
                <b-form-datepicker
                  v-if="el.type === 'date' || el.type === 'datetime'"
                  v-model="value[el.field]"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <template #footer v-if="!noButtons">
          <b-button-toolbar justify>
            <b-button @click="currentStep--" :class="previousButtonClass">
              Previous
            </b-button>

            <b-button v-if="isLastStep" @click="doSubmit">Submit</b-button>
            <b-button v-else @click="currentStep++">Next</b-button>
          </b-button-toolbar>
        </template>
      </b-card>
    </template>
  </div>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";

export default {
  props: ["config", "value", "noButtons"],
  data() {
    return {
      currentStep: 0,
      elementTypes: [
        "text",
        "email",
        "number",
        "checkbox",
        "radio",
        "dropdown",
        "date",
        "datetime",
      ],
      elementTypesWithOptions: ["checkbox", "radio", "dropdown"],
      showEdit: false,
    };
  },
  methods: {
    needsOptions(type) {
      return this.elementTypesWithOptions.includes(type);
    },
    translate(value) {
      return translateSetting(value, this.locale);
    },
    doSubmit() {
      this.$emit("submit", this.value);
    },
  },
  computed: {
    previousButtonClass() {
      if (this.isFirstStep) {
        return "invisible";
      }
      return "";
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.config.length - 1;
    },
  },
};
</script>
<style lang="scss" scoped></style>
